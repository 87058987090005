import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import sizes from 'style/sizes';
import { mq, breakpoint } from 'style/variables';
import { AboutNav } from 'components/organisms/aboutNav';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import { AboutSection } from 'components/organisms/aboutSection';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { Text } from 'components/atoms/text';
import fonts from 'style/fonts';
import { AboutStudiosQuery } from 'types/graphql-types';
import { text } from 'style/mixin';
import FadeUpItem from 'components/organisms/fadeUpItem';
import { graphql } from 'gatsby';
import SEO from 'util/seo';
import { StudioCard } from 'components/molecules/studioCard';
import { EnHeading } from 'components/atoms/enHeading';

// import { AboutStudiosQuery } from 'types/';
// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema

interface Props {
  data: AboutStudiosQuery;
}

const MainText = styled.div`
  margin-top: 60px;
  ${mq.onlysp} {
    margin-top: ${sizes.margin[49]};
  }
  p {
    font-family: ${fonts.jaGothic};

    & + p {
      margin-top: 2em;
    }
    br {
      ${mq.onlysp} {
        display: none;
      }
    }
  }
`;

const AboutText = styled(Text)`
  ${text(15, 2.5)};
  ${mq.onlysp} {
    ${text(15, 2.2)};
  }
  & + & {
    margin-top: 3.7em;
    ${mq.onlysp} {
      margin-top: 3em;
    }
  }
`;

const StudioCardCol = styled.div`
  max-width: 938px;
  margin-left: auto;
  margin-right: 0;
  ${mq.onlypc} {
    margin-bottom: ${sizes.margin[192]};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: ${sizes.margin[96]};
    row-gap: ${sizes.margin[81]};
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[100]};
    display: flex;
    row-gap: 49px;
    flex-direction: column;
    padding: 0 32px;
  }
`;

const TitleWrapper = styled.div``;

const Pages: React.FC<Props> = props => {
  const [isSp, setIsSp] = useState(false);

  const resizeHandler = useCallback(() => {
    setIsSp(window.innerWidth <= breakpoint);
  }, []);

  useEffect(() => {
    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  const studios = props.data.allShifterStudiosData.edges;

  return (
    <>
      <SEO title={'Studios | About'} en />
      <ContentWrapper>
        <AboutNav
          currentPage="Studios"
          links={[
            { text: 'Company', link: '/en/about/company' },
            { text: 'Studios', link: '/en/about/studios', active: true },
            { text: 'Vision', link: '/en/about/vision' },
            { text: 'Culture', link: '/en/about/culture' },
            { text: 'Sustainability', link: '/en/about/sustainability' },
          ]}
        />

        <AboutSection>
          <FadeUpItem delay={0.6}>
            <TitleWrapper>
              <EnHeading>Getting to where design is needed</EnHeading>
            </TitleWrapper>
          </FadeUpItem>
          <FadeUpItem delay={0.8}>
            <MainText>
              <AboutText>
                At FOURDIGIT we are expanding our business reach to where digital design is needed. We are launching
                studios in cities across Southeast Asian nations where technology continues to spread and the power of
                design is now clearly evident in business. An important priority is to move ahead together with our team
                members in those cities and countries. We are leading the market, aiming to develop the digital design
                industry together with teams that understand first-hand the local culture, thinking, and business
                issues.
              </AboutText>
            </MainText>
          </FadeUpItem>
        </AboutSection>

        <StudioCardCol>
          {studios.map(studio => (
            <StudioCard
              key={studio.node.id}
              imgpath={studio.node.acf?.thumb || 'noimage/img-card-thumb.jpg'}
              {...(studio.node.acf?.detail_page ? { to: `/en/about/studios/${studio.node.slug}` } : {})}
              place={studio.node.title?.rendered || ''}
            />
          ))}
        </StudioCardCol>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutStudiosEn {
    site {
      siteMetadata {
        title
      }
    }
    allShifterStudiosData(
      filter: { title: { rendered: { nin: ["dummy", null] } } }
      sort: { order: ASC, fields: acf___show_num }
    ) {
      edges {
        node {
          id
          title {
            rendered
          }
          slug
          acf {
            thumb
            country
            text
            text_en
            address
            contact_en
            map
            show_num
            sns {
              type
              url
            }
            detail_page
          }
        }
      }
    }
  }
`;
